/* eslint-disable class-methods-use-this */
import type {
	EventTracker,
	EventTrackingDecorator,
	EventTrackingListener,
	TrackingEvent,
} from '@change-corgi/core/eventTracker';
import { isSsr } from '@change-corgi/core/ssr';
import { getWindow } from '@change-corgi/core/window';

import { getActiveCampaigns, listenToActiveDecisions } from './campaigns';
import type { Options as FcmOptions } from './fcm';
import { getConfig } from './fcm';
import { waitForReady, waitForState } from './ready';
import {
	genOptimizelyInitScript,
	genOptimizelyOnLoadScript,
	genOptimizelyScriptUrl,
	genOptimizelyTrackingScript,
	getOptimizelyInstance,
} from './script';

type Options = FcmOptions &
	Readonly<{
		projectId: string;
	}>;

type OptimizelyScript = Readonly<{
	init: string;
	tracking: string;
	scriptUrl: string;
	onOptimizelyLoad: string;
}>;

type State = 'enabled' | 'opted-out' | 'disabled';

/* eslint-disable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */
interface Optimizely extends EventTrackingDecorator, EventTrackingListener {
	setState(state: State): void;
	setTrack(track: EventTracker['track']): this;
	getScript(optInTracking: boolean): OptimizelyScript;
	init(): this;
	preload(): this;
	onEventsTrack(events: readonly TrackingEvent[], common: Record<string, unknown>): void;
}
/* eslint-enable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */

class OptimizelyImpl implements Optimizely {
	private readonly projectId: string;
	private track?: EventTracker['track'];
	private initDone = false;
	private configPromise: ReturnType<typeof getConfig> | undefined;
	private readonly fcmOptions: FcmOptions;
	private state: State | 'unknown' = 'unknown';

	constructor({ getFeatureConfig, projectId }: Options) {
		this.projectId = projectId;
		this.fcmOptions = { getFeatureConfig };
	}

	setState(state: State): void {
		this.state = state;
	}

	setTrack(track: EventTracker['track']): this {
		this.track = track;
		return this;
	}

	getScript(optInTracking: boolean): OptimizelyScript {
		return {
			init: genOptimizelyInitScript(),
			tracking: genOptimizelyTrackingScript(optInTracking),
			scriptUrl: genOptimizelyScriptUrl(this.projectId),
			onOptimizelyLoad: genOptimizelyOnLoadScript(optInTracking),
		};
	}

	preload(): this {
		void this.getConfig();
		return this;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private get optimizely(): any {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return getOptimizelyInstance();
	}

	private pushEvent(data: Record<string, unknown>) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
		this.optimizely.push(data);
	}

	private trackEvent(eventName: string, data: { amount?: number } & Record<string, unknown>) {
		const { amount, ...restProps } = data;
		this.pushEvent({
			type: 'event',
			eventName,
			tags: {
				...(amount && { revenue: amount * 100 }),
				...restProps,
			},
		});
	}

	private filterEventData(
		eventName: string,
		data: Record<string, unknown>,
		{ eventSafelist, propertySafelist }: { eventSafelist: readonly string[]; propertySafelist: readonly string[] },
	) {
		if (!eventSafelist.includes(eventName)) return null;
		return { eventName, data: propertySafelist.reduce((a, k) => ({ ...a, [k]: data[k] }), {}) };
	}

	async getEventTrackingData(): Promise<{
		optimizely_variations?: Record<string, string>;
		optimizely_state: State | 'unknown' | 'timeout';
	}> {
		// if the state is unknown, it might be because of a race condition
		// so we wait for a short while to make sure the <Optimizely> component has time to update the state
		try {
			await waitForState(() => this.state !== 'unknown', 3000);
		} catch {
			return {
				optimizely_state: 'unknown',
			};
		}

		if (this.state !== 'enabled') {
			return {
				optimizely_state: this.state,
			};
		}

		try {
			await waitForReady(3000);

			return {
				optimizely_state: 'enabled',
				optimizely_variations: getActiveCampaigns(),
			};
		} catch (e) {
			return {
				optimizely_state: 'timeout',
			};
		}
	}

	private trackExperiment(experimentId: string | number, variationId: string | number) {
		void this.track?.('optimizely_treated', {
			optimizely_experiment: `${experimentId}`,
			optimizely_variant: `${variationId}`,
		});
	}

	private trackListenerCount = 0;
	private initTreatmentTracking() {
		// track variations that have already been treated
		const initialVariations = getActiveCampaigns();
		Object.entries(initialVariations).forEach(([experimentId, variationId]) => {
			this.trackExperiment(experimentId, variationId);
		});

		const trackListenerCount = this.trackListenerCount + 1;
		this.trackListenerCount = trackListenerCount;

		// track future treatments
		listenToActiveDecisions((decision) => {
			// disables obsolete listeners
			if (trackListenerCount !== this.trackListenerCount) return;

			this.trackExperiment(decision.experimentId, decision.variationId);
		});
	}

	init(): this {
		if (isSsr()) {
			throw new Error('Optimizely is not available server-side');
		}

		if (this.initDone) {
			return this;
		}

		this.initDone = true;

		this.initTreatmentTracking();
		// using this function allows us to handle optimizely reloads
		// see src/react/optimizely/Optimizely.tsx
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		(getWindow() as any).onOptimizelyLoad = (optin: boolean) => {
			this.setState(optin ? 'enabled' : 'opted-out');
			this.initTreatmentTracking();
		};

		return this;
	}

	private checkAvailable() {
		if (isSsr()) {
			throw new Error('Optimizely is not available server-side');
		}

		if (!this.initDone) {
			throw new Error('Optimizely has not been properly initialized');
		}

		return true;
	}

	private async getConfig() {
		if (this.configPromise) {
			return this.configPromise;
		}

		this.configPromise = getConfig(this.fcmOptions);

		return this.configPromise;
	}

	private async trackEvents(events: readonly TrackingEvent[], common: Record<string, unknown>) {
		try {
			this.checkAvailable();

			this.init();

			const { optimizelyEnabled, eventSafelist, propertySafelist } = await this.getConfig();

			if (!optimizelyEnabled) return;

			events.forEach((event) => {
				const filteredEventData = this.filterEventData(
					event.name,
					{
						...common,
						...event.data,
						...event.defaultOverrides,
					},
					{ eventSafelist, propertySafelist },
				);
				if (!filteredEventData) return;

				void this.trackEvent(filteredEventData.eventName, filteredEventData.data);
			});
		} catch (e) {
			// ignore error
		}
	}

	onEventsTrack(events: readonly TrackingEvent[], common: Record<string, unknown>): void {
		void this.trackEvents(events, common);
	}
}

export type { Optimizely };

export function createOptimizely(options: Options): Optimizely {
	return new OptimizelyImpl(options);
}

export function createOptimizelyFake(errorMsg: string): Optimizely {
	const errorFn = () => {
		throw new Error(errorMsg);
	};
	return {
		setState: errorFn,
		setTrack: errorFn,
		init: errorFn,
		getScript: errorFn,
		preload: errorFn,
		getEventTrackingData: errorFn,
		onEventsTrack: errorFn,
	};
}
