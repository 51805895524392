import type { JSX, PropsWithChildren } from 'react';

import isEqual from 'lodash/isEqual';
import { useCustomCompareMemo } from 'use-custom-compare';

import type { TrackOptions } from '@change-corgi/core/eventTracker';

import { TrackingContextProvider as TrackingContextProviderBase } from './context';
import { useTracking } from './hook';
import type { TrackingEventProperties, TrackingFn } from './types';

type Props = {
	properties: TrackingEventProperties;
	/**
	 * can be used to disable tracking within the context
	 *
	 * useful for accessing a page in a mode that shouldn't impact tracking (e.g. preview mode)
	 */
	disabled?: boolean;
} & Pick<TrackOptions, 'defaultOverrides'>;

function propsToHookDeps(properties: TrackingEventProperties) {
	// there is no guarantee in JS that order will stay consistent, so let's sort the entries
	return Object.entries(properties)
		.sort(([key1], [key2]) => (key1 < key2 ? -1 : 1))
		.reduce<unknown[]>((acc, [key, value]) => {
			acc.push(key, value);
			return acc;
		}, []);
}

function areDepsEqual(
	[track1, properties1, disabled1]: readonly [TrackingFn, TrackingEventProperties, boolean],
	[track2, properties2, disabled2]: readonly [TrackingFn, TrackingEventProperties, boolean],
): boolean {
	if (track1 !== track2) return false;
	if (disabled1 !== disabled2) return true;
	if (properties1 === properties2) return true;
	const flattenProperties1 = propsToHookDeps(properties1);
	const flattenProperties2 = propsToHookDeps(properties2);
	return (
		flattenProperties1.length === flattenProperties2.length &&
		// let's avoid triggering unnecessary re-renders when the actual values don't change
		flattenProperties1.every((value, index) => isEqual(value, flattenProperties2[index]))
	);
}

export function TrackingContextProvider({
	children,
	disabled = false,
	properties,
	defaultOverrides,
}: PropsWithChildren<Props>): JSX.Element {
	const track = useTracking();

	const wrappedTrack: TrackingFn = useCustomCompareMemo(
		() => async (name, additionalProperties, additionalOptions) => {
			if (disabled) {
				return false;
			}
			const props = {
				...properties,
				...additionalProperties,
			};
			const options = {
				defaultOverrides: {
					...defaultOverrides,
					...additionalOptions?.defaultOverrides,
				},
			};
			if (Object.keys(options.defaultOverrides).length) {
				return track(name, props, options);
			}
			return track(name, props);
		},
		[track, properties, disabled],
		areDepsEqual,
	);

	return <TrackingContextProviderBase value={wrappedTrack}>{children}</TrackingContextProviderBase>;
}
