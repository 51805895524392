/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Path } from 'react-router';
import { createPath, NavigationType, parsePath as parsePathBase } from 'react-router';

type Listener = (options: { action: NavigationType; location: Path; delta: number }) => void;

type Options = { initialEntries?: ReadonlyArray<Partial<Path> | string>; initialIndex?: number };

function parsePath(url: string | Partial<Path>): Path {
	return { pathname: '', search: '', hash: '', ...(typeof url !== 'string' ? url : parsePathBase(url)) };
}

type FakeHistory = {
	readonly index: number;
	readonly action: NavigationType;
	readonly location: Readonly<Location>;
	listen: (listener: Listener) => () => void;
	push: (url: string) => void;
	replace: (url: string) => void;
	go: (delta: number) => void;
	/**
	 * rewrite all entries and trigger listeners on index
	 *
	 * Only useful in very specific cases
	 */
	setEntries: (newEntries: readonly string[], newIndex: number) => void;
	createHref: (to: string | Partial<Path>) => string;
};

type Location = Path & { state?: any };

function createLocation(url: string | Partial<Path>, state?: any): Location {
	return { ...parsePath(url), state };
}

export type { FakeHistory };

// eslint-disable-next-line max-lines-per-function
export function createFakeHistory({ initialEntries, initialIndex }: Options = {}): FakeHistory {
	let listeners: Listener[] = [];
	let entries = (initialEntries ? [...initialEntries] : ['/']).map((entry) => createLocation(entry));
	// eslint-disable-next-line @typescript-eslint/naming-convention
	let _index = initialIndex ?? entries.length - 1;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	let _action = NavigationType.Pop;

	function triggerListeners(location: Location, action: NavigationType, delta: number) {
		listeners.forEach((listener) => listener({ location, action, delta }));
	}

	return {
		get index(): number {
			return _index;
		},

		get action(): NavigationType {
			return _action;
		},

		get location(): Readonly<Location> {
			return entries[_index];
		},

		listen(listener: Listener): () => void {
			listeners.push(listener);
			return () => {
				listeners = listeners.filter((fn) => fn !== listener);
			};
		},

		push(url: string, state?: any): void {
			const path = createLocation(url, state);
			entries = entries.slice(0, _index + 1);
			entries.push(path);
			_index += 1;
			_action = NavigationType.Push;
			triggerListeners(path, _action, 1);
		},

		replace(url: string, state?: any): void {
			const path = createLocation(url, state);
			entries = entries.slice(0, _index);
			entries.push(path);
			_action = NavigationType.Replace;
			triggerListeners(path, _action, 0);
		},

		go(delta: number): void {
			_index += delta;
			_action = NavigationType.Pop;
			triggerListeners(entries[_index], _action, delta);
		},

		setEntries(newEntries: ReadonlyArray<string | Partial<Path>>, newIndex: number): void {
			entries = newEntries.map((entry) => createLocation(entry));
			_index = newIndex;
			_action = NavigationType.Pop;
			triggerListeners(entries[_index], _action, 0);
		},

		createHref(to: string | Partial<Path>): string {
			return typeof to === 'string' ? to : createPath(to);
		},
	};
}
