import type { EventTrackingDecorator } from '@change-corgi/core/eventTracker';
import type { GqlClient } from '@change-corgi/core/gql';

import { getTreatableExperiments } from './getTreatableExperiments';
import type { Options as ExperimentsOverridesOptions, IExperimentOverrides } from './overrides';
import { createOverrides } from './overrides';
import { createTreatedStore } from './TreatedStore';
import type { Experiment, TreatableExperiment } from './types';
import { createDummyExperiment } from './types';

type Options = ExperimentsOverridesOptions &
	Readonly<{
		/**
		 * should be null if experiments could not be retrieved (ie because of server error)
		 */
		experiments?: readonly Experiment[] | null;
		/**
		 * Overrides that come from another source than cookies (e.g. query string)
		 *
		 * Those will take precedence over cookies
		 */
		overrides: Readonly<Record<string, string>>;
		gqlFetch: GqlClient['fetch'];
	}>;

/* eslint-disable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */
interface Experiments extends EventTrackingDecorator {
	readonly overrides: IExperimentOverrides;
	get<VARIATION extends string = string>(id: string): TreatableExperiment<VARIATION>;
	getAll(): Readonly<Record<string, TreatableExperiment>> | undefined;
	getKnownExperimentsList(): Experiment[] | null | undefined;
	isServiceError(): boolean;
	getEventTrackingData(): Readonly<Record<string, unknown>>;
	/**
	 * add useful functions to the window object
	 *
	 * @example
	 * window._sov(experimentId, variation)
	 * window._exp.all()
	 * window._exp.get(experimentId)
	 * window._exp.set(experimentId, variation)
	 * window._exp.unset(experimentId)
	 * window._exp.clear()
	 */
	decorateWindow(): this;
}
/* eslint-enable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */

class ExperimentsImpl implements Experiments {
	private readonly _experiments: Readonly<Record<string, TreatableExperiment>> | undefined;
	private readonly _experimentsServiceError: boolean = false;

	private readonly gqlFetch: Options['gqlFetch'];

	private readonly treatedStore = createTreatedStore();

	readonly overrides: IExperimentOverrides;

	constructor({ gqlFetch, cookies, experiments, overrides }: Options) {
		this.gqlFetch = gqlFetch;
		this.overrides = createOverrides({ cookies, overrides });

		if (experiments !== undefined) {
			const treatableExperiments = getTreatableExperiments(experiments || undefined, {
				treatedStore: this.treatedStore,
				overrides: this.overrides,
				gqlFetch: this.gqlFetch,
			});
			this._experiments = (treatableExperiments || []).reduce<Record<string, TreatableExperiment>>(
				(acc, experiment) => {
					// eslint-disable-next-line no-param-reassign
					acc[experiment.id] = experiment;
					return acc;
				},
				{},
			);
			this._experimentsServiceError = experiments === null;
		}
	}

	get<VARIATION extends string = string>(id: string): TreatableExperiment<VARIATION> {
		if (!this._experiments) {
			throw new Error('Experiments were not initialized');
		}

		const experiment = this._experiments[id] as TreatableExperiment<VARIATION> | undefined;

		if (!experiment) {
			return createDummyExperiment(id);
		}

		return experiment;
	}

	getAll(): Readonly<Record<string, TreatableExperiment>> | undefined {
		return this._experiments;
	}

	/**
	 * @returns the updated list of known experiments in the format that was passed to the constructor
	 */
	getKnownExperimentsList(): Experiment[] | null | undefined {
		if (this.isServiceError()) {
			return null;
		}
		if (!this._experiments) {
			return undefined;
		}
		return (
			Object.values(this._experiments)
				// remove unknown experiments (ie experiments that don't match a retrieved experiment)
				.filter(({ originalExperiment }) => !!originalExperiment)
				.map(({ id, treated, variation }) => ({ id, treated, variation }))
		);
	}

	isServiceError(): boolean {
		return this._experimentsServiceError;
	}

	getEventTrackingData(): Readonly<Record<string, unknown>> {
		const experimentsList = Object.values(this._experiments || {})
			// remove unknown experiments (ie experiments that don't match a retrieved experiment)
			.filter(({ originalExperiment }) => !!originalExperiment)
			.map(({ id, treated, variation, overridden }) => ({ id, treated, variation, overridden }))
			.sort((a, b) => (a.id < b.id ? -1 : 1));
		const experimentsServiceError = this.isServiceError();

		return {
			// for A/B testing
			experiment_variations: experimentsList.reduce<Record<string, string>>((acc, experiment) => {
				if (!experiment.treated) {
					return acc;
				}
				// eslint-disable-next-line no-param-reassign
				acc[experiment.id] = experiment.variation;
				return acc;
			}, {}),
			...(experimentsServiceError ? { experiment_service_error: true } : {}),
		};
	}

	decorateWindow(): this {
		this.overrides.decorateWindow();

		return this;
	}
}

export type { Experiments };

export function createExperiments(options: Options): Experiments {
	return new ExperimentsImpl(options);
}

export function createExperimentsFake(errorMsg: string): Experiments {
	const errorFn = () => {
		throw new Error(errorMsg);
	};
	return {
		get overrides() {
			return errorFn();
		},
		decorateWindow: errorFn,
		get: errorFn,
		getAll: errorFn,
		getKnownExperimentsList: errorFn,
		getEventTrackingData: errorFn,
		isServiceError: errorFn,
	};
}
