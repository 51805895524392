/* eslint-disable max-classes-per-file */
/* eslint-disable max-lines */
import type { ClientOptions, SeverityLevel as SentrySeverityLevel } from '@sentry/core';
import merge from 'lodash/merge';

import type { Environment } from '@change-corgi/core/environment';
import { findPropertyInErrorStack } from '@change-corgi/core/error';
import { createUserAgentUtils, type UserAgentUtils } from '@change-corgi/core/userAgent';
import { getWindowSafe } from '@change-corgi/core/window';

import { toFlatObject } from './toFlatObject';
import type {
	ChildOptions,
	ErrorReporter,
	ReactErrorInfo,
	ReportableError,
	ReportOptions,
	SentryScope,
	SessionInfo,
	Severity,
} from './types';

export const DEFAULT_SILENCED_ENVS: readonly Environment[] = ['development'];

type SentryConfig = Readonly<{
	dsn: string;
	/**
	 * @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-thirdpartyerrorfilterintegration
	 */
	applicationKey?: string;
	/**
	 * Report errors even if their stacktrace only contains third-party frames
	 */
	reportThirdPartyErrors?: boolean;
	/**
	 * Global sample rate
	 */
	sampleRate?: number;
	/**
	 * Browser only
	 *
	 * DOM selectors to mark content as containing PII (mask) or not (unmask)
	 *
	 * Used for recording session replays (only used for browser error reporting)
	 *
	 * For safety, if content is not within a selector, it will be considered masked
	 */
	htmlPiiSelectors?: {
		mask: string[];
		unmask: string[];
	};
	/**
	 * Config for breadcrumbs
	 */
	breadcrumbs?: {
		/**
		 * any xhr/http breadcrumbs with these domains will be dropped
		 */
		ignoredDomains?: ReadonlyArray<string | RegExp>;
		/**
		 * Browser only
		 *
		 * see https://docs.sentry.io/platforms/javascript/configuration/integrations/breadcrumbs/#dom
		 */
		domSerializedAttributes?: string[];
	};
}>;

type WebappInfo = Readonly<{
	/**
	 * name of app
	 */
	name: string;
	/**
	 * version of app (or SHA of last commit)
	 */
	version: string;
	/**
	 * release of app (<name>@<version> or <name>@<version>+<build or SHA of last commit)
	 */
	release: string;
}>;

export type SentryOptions = {
	config: SentryConfig;
	environment: Environment;
	options: Pick<
		ClientOptions,
		| 'dsn'
		| 'release'
		| 'environment'
		| 'beforeSend'
		| 'beforeSendSpan'
		| 'beforeSendTransaction'
		| 'sampleRate'
		| 'tracesSampleRate'
		| 'tracePropagationTargets'
		| 'normalizeDepth'
	>;
};

type Options = Readonly<{
	environment: Environment;
	subEnvironment: string;
	webappInfo: WebappInfo;
	hostname?: string;
	silencedBots?: boolean;
	silencedEnvironments?: readonly Environment[];
	createSentryScope?: (options: SentryOptions) => SentryScope;
	additionalParams?: () => Record<string, unknown> | undefined;
	additionalContext?: (context: { error: unknown }) => Record<string, unknown> | undefined;
	userAgent?: string;
	reporters: Readonly<{
		sentry?: SentryConfig;
	}>;
}>;

const MAP_SENTRY_SEVERITY: Record<Severity, SentrySeverityLevel> = {
	debug: 'debug',
	info: 'info',
	notice: 'info',
	warning: 'warning',
	error: 'error',
	critical: 'fatal',
	alert: 'warning',
	emergency: 'fatal',
	invalid: 'error',
};

function genSentryIssueUrl(issueId: string, env: string): string {
	return `https://changeorg.sentry.io/issues/?environment=${env}&project=-1&statsPeriod=30d&query=id%3a${issueId}`;
}

abstract class AbstractErrorReporter {
	// eslint-disable-next-line class-methods-use-this
	private methodNotAvailable(): never {
		throw new Error('Method not available.');
	}

	init(): this {
		this.methodNotAvailable();
	}

	setSessionInfo(_sessionInfo: SessionInfo): this {
		this.methodNotAvailable();
	}

	updateSessionInfo(_sessionInfo: SessionInfo): void {
		this.methodNotAvailable();
	}

	getSessionInfo(): SessionInfo | undefined {
		this.methodNotAvailable();
	}

	toggleLogging(_enabled?: boolean): this {
		this.methodNotAvailable();
	}

	reactErrorHandler(_options?: { sampleRate?: number }): (error: unknown, errorInfo: ReactErrorInfo) => void {
		this.methodNotAvailable();
	}

	abstract report(error: ReportableError, options?: ReportOptions & { silenced?: boolean }): Promise<{ url?: string }>;

	wrap<RET>(fnToExec: () => RET): RET {
		try {
			return fnToExec();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			void this.report(e);
			throw e;
		}
	}

	wrapFunction<ARGS, RET>(fnToWrap: (...args: ARGS[]) => RET): (...args: ARGS[]) => RET {
		return (...args: ARGS[]) => this.wrap(() => fnToWrap(...args));
	}

	async wrapAsync<RET>(promiseToWrap: Promise<RET>): Promise<RET> {
		// eslint-disable-next-line promise/prefer-await-to-then
		return promiseToWrap.catch((e) => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			void this.report(e);
			throw e;
		});
	}

	wrapAsyncFunction<ARGS, RET>(fnToWrap: (...args: ARGS[]) => Promise<RET>): (...args: ARGS[]) => Promise<RET> {
		return async (...args: ARGS[]) => this.wrapAsync(fnToWrap(...args));
	}

	createSampledReporter(
		sampleRate: number,
	): (error: ReportableError, options?: ReportOptions) => Promise<{ url?: string }> {
		const childReporter = this.child({ sampleRate });
		return childReporter.report.bind(childReporter);
	}

	abstract child(options: ChildOptions): ErrorReporter;
}

class BaseErrorReporter extends AbstractErrorReporter implements ErrorReporter {
	private readonly sentryConfig: SentryConfig | undefined;
	private readonly environment: Environment;
	private readonly subEnvironment: string;
	private readonly webappInfo: WebappInfo;
	private readonly hostname?: string;
	private readonly silencedEnvironments: readonly Environment[];
	private readonly createSentryScope?: (options: SentryOptions & { silenced?: boolean }) => SentryScope;
	private readonly additionalContext?: (context: { error: unknown }) => Record<string, unknown> | undefined;
	private readonly additionalParams?: () => Record<string, unknown> | undefined;
	private readonly userAgent: UserAgentUtils | undefined;

	private sentryScope: SentryScope | undefined;
	private loggingEnabled = false;

	private sessionInfo?: SessionInfo;

	constructor({
		environment,
		subEnvironment,
		webappInfo,
		hostname,
		silencedEnvironments,
		reporters,
		createSentryScope,
		additionalContext,
		additionalParams,
	}: Options) {
		super();
		this.sentryConfig = reporters.sentry;
		this.hostname = hostname;
		this.environment = environment;
		this.subEnvironment = subEnvironment;
		this.webappInfo = webappInfo;
		this.silencedEnvironments = silencedEnvironments || DEFAULT_SILENCED_ENVS;
		this.createSentryScope = createSentryScope;
		this.additionalContext = additionalContext;
		this.additionalParams = additionalParams;
		const userAgent = getWindowSafe()?.navigator.userAgent;
		this.userAgent = userAgent ? createUserAgentUtils({ userAgent }) : undefined;

		// making sure sentry is initialized right away
		this.getSentryScope();
	}

	setSessionInfo(sessionInfo: SessionInfo): this {
		this.sessionInfo = sessionInfo;
		return this;
	}

	updateSessionInfo(sessionInfo: SessionInfo): void {
		this.sessionInfo = {
			...this.sessionInfo,
			...sessionInfo,
		};
	}

	getSessionInfo(): SessionInfo | undefined {
		return this.sessionInfo;
	}

	// eslint-disable-next-line max-lines-per-function
	protected getSentryScope(): SentryScope | null {
		if (this.sentryScope) {
			return this.sentryScope;
		}
		if (!this.createSentryScope || !this.sentryConfig) {
			return null;
		}
		const globalSampleRate = this.sentryConfig.sampleRate || 1.0;
		this.sentryScope = this.createSentryScope({
			config: this.sentryConfig,
			environment: this.environment,
			silenced: this.silencedEnvironments.includes(this.environment),
			options: {
				dsn: this.sentryConfig.dsn,
				release: this.webappInfo.release,
				environment: this.subEnvironment,
				// TODO explore distributed tracing
				// https://docs.sentry.io/concepts/key-terms/tracing/distributed-tracing/
				tracesSampleRate: 0,
				tracePropagationTargets: [],
				sampleRate: globalSampleRate,
				normalizeDepth: 7,
				beforeSendTransaction: (transaction) =>
					this.silencedEnvironments.includes(this.environment) ? null : transaction,
				// eslint-disable-next-line complexity, max-statements, max-lines-per-function
				beforeSend: (event, hint) => {
					const userAgent =
						this.userAgent ||
						(event.tags?.userAgent ? createUserAgentUtils({ userAgent: event.tags?.userAgent as string }) : undefined);
					const { sampled, sampleRate = 1.0, ...tags } = event.tags || {};
					// eslint-disable-next-line no-param-reassign
					event.extra = {
						...event.extra,
						...this.additionalParams?.(),
					};
					// eslint-disable-next-line no-param-reassign
					event.tags = {
						...tags,
						hostname: this.hostname,
						bot: userAgent?.isBot(),
						...toFlatObject(
							merge(
								{
									device: userAgent
										? {
												...userAgent.getParsedUserAgent().getDevice(),
												type: userAgent.getParsedUserAgent().getDevice().type || 'desktop',
											}
										: undefined,
									browser: userAgent
										? { ...userAgent?.getParsedUserAgent().getBrowser(), webview: userAgent.getWebviewType() }
										: undefined,
									os: userAgent?.getParsedUserAgent().getOS(),
									sampled:
										globalSampleRate < 1 || (sampleRate as number) < 1
											? {
													global: globalSampleRate * 100,
													local: (sampleRate as number) * 100,
													final: globalSampleRate * (sampleRate as number) * 100,
												}
											: undefined,
									session: this.getSessionInfo(),
								},
								this.additionalContext?.({ error: hint.originalException }),
							),
						),
					};

					// Sentry does not transform Response objects into a readable error
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					if ((event.message as any) instanceof Response) {
						const response = event.message as unknown as Response;
						const requestId = getResponseRequestId(response);
						/* eslint-disable no-param-reassign */
						event.message = `HTTP Response ${response.status} ${response.statusText}`;
						event.tags = {
							...event.tags,
							requestId,
							...toFlatObject({
								response: {
									statusCode: response.status,
									url: response.url,
								},
							}),
						};
						event.contexts = event.contexts || {};
						event.contexts.response = {
							status_code: response.status,
							url: response.url,
							request_id: requestId,
						};
						if (event.exception?.values?.[0]) {
							event.exception.values[0].type = 'HTTP Response';
							event.exception.values[0].value = event.message;
						}
						/* eslint-enable no-param-reassign */
					}
					if (hint.originalException instanceof Error && hint.originalException.cause instanceof Response) {
						const response = hint.originalException.cause as unknown as Response;
						const requestId = getResponseRequestId(response);
						/* eslint-disable no-param-reassign */
						event.tags = {
							...event.tags,
							requestId,
						};
						event.contexts = event.contexts || {};
						event.contexts.Error = {
							cause: {
								status_code: response.status,
								url: response.url,
								request_id: requestId,
							},
						};
						/* eslint-enable no-param-reassign */
					}
					Object.entries(event.contexts || {}).forEach(([_key, value]) => {
						if (value && typeof value.requestId === 'string') {
							// eslint-disable-next-line no-param-reassign
							event.tags = {
								...event.tags,
								requestId: value.requestId,
							};
						}
					});
					if (!event.tags.requestId) {
						const requestId = findPropertyInErrorStack<string>(hint.originalException, 'requestId');
						if (typeof requestId === 'string') {
							// eslint-disable-next-line no-param-reassign
							event.tags = {
								...event.tags,
								requestId,
							};
						}
					}

					const silenced = this.silencedEnvironments.includes(this.environment) || sampled === false;
					/* eslint-disable no-console */
					if (this.loggingEnabled) {
						console.groupCollapsed(
							'%cRECORDED ERROR (Sentry):',
							'color: red',
							event.exception?.values?.[event.exception.values.length - 1].value || '<no message>',
						);
						console.log('exceptions:', event.exception?.values);
						console.log('extra:', event.extra);
						console.log('tags:', event.tags);
						console.log('contexts:', event.contexts);
						console.log('environment:', event.environment);
						console.log('level:', event.level);
						sampled !== undefined && console.log('sampled:', sampled);
						!silenced &&
							event.event_id &&
							console.log('issue url', genSentryIssueUrl(event.event_id, this.subEnvironment));
						console.log('sent to Sentry:', !silenced);
						console.groupEnd();
					}
					/* eslint-enable no-console */
					return silenced ? null : event;
				},
			},
		});
		return this.sentryScope;
	}

	toggleLogging(enabled?: boolean): this {
		this.loggingEnabled = enabled === undefined ? !this.loggingEnabled : enabled;
		return this;
	}

	init(): this {
		this.getSentryScope();
		return this;
	}

	async report(error: ReportableError, options: ReportOptions = {}): Promise<{ url?: string }> {
		this.init();

		const notice =
			typeof error === 'object' && 'error' in error && !(error instanceof Error) ? { ...error } : { error };

		const sentryScope = this.getSentryScope();
		let sentryUrl: string | undefined;
		if (sentryScope) {
			const id = sentryScope.captureException(notice.error, {
				captureContext: {
					level: MAP_SENTRY_SEVERITY[options.severity as Severity] || 'error',

					extra: {
						...notice.params,
					},
					// TODO add tags (https://docs.sentry.io/platforms/javascript/enriching-events/tags/)
					tags: toFlatObject({
						...notice.context,
					}),
				},
			});
			sentryUrl = genSentryIssueUrl(id, this.subEnvironment);
		}

		return Promise.resolve({ url: sentryUrl });
	}

	// eslint-disable-next-line class-methods-use-this
	destroy(): void {
		// do nothing
	}

	reactErrorHandler({ sampleRate = 1 }: { sampleRate?: number } = {}): (
		error: unknown,
		errorInfo: ReactErrorInfo,
	) => void {
		if (sampleRate < 0 || sampleRate > 1) {
			throw new Error('Invalid Sample Rate');
		}

		const sampled = Math.random() < sampleRate;
		const sentryScope = this.getSentryScope();
		return (error, errorInfo) => {
			if (sentryScope?.captureReactException) {
				sentryScope.captureReactException(error, errorInfo, {
					captureContext: {
						// this matches what createSampledReporter does
						tags: { sampleRate, sampled },
					},
				});
			}
		};
	}

	child(options: ChildOptions): ErrorReporter {
		return new ChildErrorReporter(this, options);
	}
}

class ChildErrorReporter extends AbstractErrorReporter implements ErrorReporter {
	readonly parent: ErrorReporter;
	readonly options: ChildOptions;
	readonly sampling?: {
		sampleRate: number;
		sampled: boolean;
	};

	constructor(parent: ErrorReporter, options: ChildOptions) {
		super();
		this.parent = parent;
		this.options = options;

		if (typeof this.options.sampleRate !== 'undefined') {
			const { sampleRate } = this.options;
			if (sampleRate < 0 || sampleRate > 1) {
				throw new Error('Invalid Sample Rate');
			}

			const sampled = Math.random() < sampleRate;
			this.sampling = {
				sampleRate,
				sampled,
			};
		}
	}

	// eslint-disable-next-line class-methods-use-this
	private genOptionsData(data: ChildOptions['context']) {
		if (!data) return undefined;
		if (typeof data === 'function') {
			return data();
		}
		return data;
	}

	private genReportableError(error: ReportableError) {
		const notice = typeof error === 'object' && 'error' in error ? { ...error } : { error };
		if (notice.context || this.options.context || this.sampling) {
			notice.context = merge({}, notice.context, this.genOptionsData(this.options.context), this.sampling);
		}
		if (notice.params || this.options.params) {
			notice.params = { ...notice.params, ...this.genOptionsData(this.options.params) };
		}
		return notice;
	}

	async report(error: ReportableError, options?: ReportOptions): Promise<{ url?: string }> {
		return this.parent.report(this.genReportableError(error), options);
	}

	createSampledReporter(
		sampleRate: number,
	): (error: ReportableError, options?: ReportOptions) => Promise<{ url?: string }> {
		const childReporter = this.child({ sampleRate });
		return childReporter.report.bind(childReporter);
	}

	// eslint-disable-next-line class-methods-use-this
	destroy(): void {}

	child(options: ChildOptions): ErrorReporter {
		return new ChildErrorReporter(this, options);
	}
}

function getResponseRequestId(cause: Response) {
	try {
		return cause.headers.get('x-request-id');
	} catch {
		return undefined;
	}
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __internal__BaseErrorReporter__ = BaseErrorReporter;

export function createErrorReporter(options: Options): ErrorReporter {
	return new BaseErrorReporter(options);
}

export function createErrorReporterFake(errorMsg: string): ErrorReporter {
	const errorFn = () => {
		throw new Error(errorMsg);
	};
	return {
		init: errorFn,
		setSessionInfo: errorFn,
		updateSessionInfo: errorFn,
		getSessionInfo: errorFn,
		toggleLogging: errorFn,
		report: errorFn,
		createSampledReporter: errorFn,
		wrap: errorFn,
		wrapFunction: errorFn,
		wrapAsync: errorFn,
		wrapAsyncFunction: errorFn,
		destroy: errorFn,
		reactErrorHandler: errorFn,
		child: errorFn,
	};
}
