import { gql } from 'graphql-tag';

import type { GqlClient } from '@change-corgi/core/gql';

export type FeCoreTreatExperimentMutationVariables = { id: string };
export type FeCoreTreatExperimentMutation = { readonly treatExperiment: { readonly id: string } };

export async function treatExperiment(id: string, context: { gqlFetch: GqlClient['fetch'] }): Promise<void> {
	try {
		await context.gqlFetch<FeCoreTreatExperimentMutation, FeCoreTreatExperimentMutationVariables>({
			query: gql`
				mutation FeCoreTreatExperiment($id: ID!) {
					treatExperiment(id: $id) {
						id
					}
				}
			`,
			variables: { id },
			rejectOnError: false,
		});
	} catch (e) {
		// error already reported by shared/gql
	}
}
