import type { GraphQLRequest } from '@apollo/client';

type GqlDetails =
	| {
			batched: true;
			operationNames: readonly string[];
			body: readonly GraphQLRequest[];
	  }
	| {
			batched: false;
			operationName: string;
			body: GraphQLRequest;
	  };

function isGraphQLRequestBatch(
	request: GraphQLRequest | readonly GraphQLRequest[],
): request is readonly GraphQLRequest[] {
	return Array.isArray(request);
}

export function getRequestGqlDetails(init?: RequestInit): GqlDetails | undefined {
	if (!init?.body) return undefined;
	if (typeof init.body !== 'string') return undefined;
	try {
		const body = JSON.parse(init.body) as GraphQLRequest | readonly GraphQLRequest[];
		if (isGraphQLRequestBatch(body)) {
			return {
				batched: true,
				operationNames: body.map(({ operationName }) => operationName || 'unknown'),
				body,
			};
		}
		return {
			batched: false,
			operationName: body.operationName || 'unknown',
			body,
		};
	} catch (e) {
		return undefined;
	}
}
