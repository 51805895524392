import { jwtDecode } from 'jwt-decode';

import type { ReportableError } from '@change-corgi/core/errorReporter/common';
import { isSsr } from '@change-corgi/core/ssr';

import { initGoogleSignInSDK } from './initSdk';
import type {
	CredentialPayload,
	CredentialResponse,
	GoogleSignInCallback,
	GsiButtonConfiguration,
	GSIFunctions,
} from './types';

type RenderButtonParams = {
	parent: HTMLElement;
	buttonOptions?: GsiButtonConfiguration;
	handleGoogleSignIn: GoogleSignInCallback;
};
/* eslint-disable-next-line @typescript-eslint/consistent-type-definitions */
interface GoogleAuth {
	preload: () => Promise<this>;
	renderButton: (options: RenderButtonParams) => Promise<boolean>;
	prompt: (handleGoogleSignIn: GoogleSignInCallback) => Promise<boolean>;
	disabled: boolean;
}

type Options = {
	googleAuthClientId: string;
	reportError: (error: ReportableError) => void;
	disabled?: boolean;
};

class GoogleAuthImpl implements GoogleAuth {
	private googleAuthClientId: string;
	private reportError: (error: ReportableError) => void;
	private googleSignInSDK: Promise<GSIFunctions | undefined> | undefined;
	readonly disabled: boolean = false;

	constructor({ googleAuthClientId, reportError, disabled }: Options) {
		this.googleAuthClientId = googleAuthClientId;
		this.reportError = reportError;
		this.googleSignInSDK = undefined;
		this.disabled = !!disabled;
	}

	private async initSDK(): Promise<GSIFunctions | undefined> {
		if (isSsr()) throw new Error('Google Sign In is not available server-side');
		if (this.disabled) return undefined;

		this.googleSignInSDK = this.googleSignInSDK || initGoogleSignInSDK({ reportError: this.reportError });
		return this.googleSignInSDK;
	}

	private async initClient(handleGoogleSignIn: GoogleSignInCallback): Promise<GSIFunctions | undefined> {
		const gsiFunctions = await this.initSDK();
		if (!gsiFunctions) {
			return undefined;
		}
		gsiFunctions.initialize({
			client_id: this.googleAuthClientId,
			callback: (response: CredentialResponse) => {
				if (response.credential) {
					const tokenPayload = jwtDecode<CredentialPayload>(response.credential);
					handleGoogleSignIn({
						token: response.credential,
						data: {
							identifier: tokenPayload.sub,
							givenName: tokenPayload.given_name,
							familyName: tokenPayload.family_name,
							displayName: tokenPayload.name,
							profilePicture: tokenPayload.picture,
							email: tokenPayload.email,
							emailVerified: tokenPayload.email_verified,
							gSuiteDomain: tokenPayload.hd,
						},
					});
				} else {
					handleGoogleSignIn({ token: undefined, data: undefined });
				}
			},
		});
		return gsiFunctions;
	}

	async preload(): Promise<this> {
		await this.initSDK();
		return this;
	}

	async renderButton(options: RenderButtonParams): Promise<boolean> {
		const gsiFunctions = await this.initClient(options.handleGoogleSignIn);
		if (!gsiFunctions) return false;

		const defaultButtonOptions: GsiButtonConfiguration = { type: 'standard', theme: 'filled_blue' };
		gsiFunctions.renderButton(options.parent, options.buttonOptions || defaultButtonOptions);
		return true;
	}

	async prompt(handleGoogleSignIn: GoogleSignInCallback): Promise<boolean> {
		const gsiFunctions = await this.initClient(handleGoogleSignIn);
		if (!gsiFunctions) return false;

		gsiFunctions.prompt();
		return true;
	}
}

export type { GoogleAuth };

export function createGoogleAuth(options: Options): GoogleAuth {
	return new GoogleAuthImpl(options);
}

export function createGoogleAuthFake(errorMsg: string): GoogleAuth {
	const errorFn = () => {
		throw new Error(errorMsg);
	};
	return {
		disabled: false,
		preload: errorFn,
		renderButton: errorFn,
		prompt: errorFn,
	};
}
